import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,[_c('span',[_vm._v("New Product Data")]),_c(VSpacer),_c(VBtnToggle,{attrs:{"dense":"","color":"primary"}},[_c(VBtn,{attrs:{"outlined":""}},[_vm._v(" Daily ")]),_c(VBtn,{attrs:{"outlined":""}},[_vm._v(" Monthly ")]),_c(VBtn,{attrs:{"outlined":""}},[_vm._v(" Yearly ")])],1)],1),_c(VCardText,[_c('chartjs-component-scatter-chart',{attrs:{"height":400,"data":_vm.chartjsData.scatterChart.data,"options":_vm.chartjsData.scatterChart.options,"plugins":_vm.plugins}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
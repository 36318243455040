import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,[_c('span',[_vm._v("Average Skills")]),_c(VSpacer),_c(VMenu,{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDotsVertical)+" ")])],1)]}}])},[_c(VList,_vm._l((_vm.items),function(item,i){return _c(VListItem,{key:i},[_c(VListItemTitle,[_vm._v(_vm._s(item.title))])],1)}),1)],1)],1),_c(VCardText,[_c('chartjs-component-polar-area-chart',{attrs:{"height":350,"data":_vm.chartjsData.polarChart.data,"options":_vm.chartjsData.polarChart.options}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }